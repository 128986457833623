.articles-creaate{
    width: 90%;
    background-color: #f1f3f4;
    margin: auto;
    padding-top: 3%;
    padding-bottom: 5%;
    padding-left: 2%;
    padding-right: 2%;
}
.articles-creaate h6{
    font-size: 105%;
    text-align: left;
    /* margin-left:5%; */
    margin-bottom: 3%;
}
.articles-lists h6{
    font-size: 105%;
    text-align: left;
    /* margin-left:5%; */
    margin-bottom: 3%;
}



.articles-lists{
    width: 88%;
    margin: auto;
    padding-top: 5%;
    margin-bottom: 10%;
}

.articles-calls p{
  /* text-align: center; */
}
.articles-calls p img{
    width: 20%;
    float: right;
}
.articles-calls h5{
    font-size: 80% !important;;
    float: left !important;
}
.articles-calls div {
    /* background: red; */
    text-align:center !important;
    padding-top: 40%;
}
.articles-calls div h1{
   
    font-weight: 400;
    font-size: 240%;   
}

.articles-list-sub{
    display: flex;
}



.articles-list-footer{
    border-top: 1px solid #dfe1e5;;
    position: relative;
    background-color: #DEE0F3;
    width: 100%;
    padding-top: 1%;
    padding-left: 4%;
    padding-right: 3%;
    border-radius: 0px 0px 5px 5px;
}
.articles-list-footer h4{
    text-align: left;
     font-size: 90%;
     text-transform: capitalize;
     margin-bottom: 4%;

}
.articles-list-footer h6{
    text-align: left !important;
    font-size: 70%;
    position: relative;
    bottom: 6px;
    /* color: #e2e2e2;; */
}

.wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    bottom: 5px;
    left: 15%;
    cursor: pointer;
    z-index: 3;
    /* background-color: red; */
  }
  .file-upload i{
    cursor: pointer; 
  }
  .wrapper .file-upload {
    height: 38px;
    width: 38px;
    border-radius: 100px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 4px solid #FFFFFF;
    overflow: hidden;
    background-image: linear-gradient(to bottom, #2590EB 50%, #FFFFFF 50%);
    background-size: 100% 200%;
    transition: all 1s;
    color: #FFFFFF;
    font-size: 20px;
    cursor: pointer;
  }
  .wrapper .file-upload input[type=file] {
    height: 200px;
    width: 200px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }
  .wrapper .file-upload:hover {
    background-position: 0 -100%;
    color: #2590EB;
    cursor: pointer;
  }
  .articles-list-card img{
      margin-top: 10%;
      margin-bottom: 8%;
  }


    /* web  view */
 @media (min-width: 1024px)  {
    .articles-creaate-sub{
        display: flex;
        margin-top: 2%;
        width: 90%;
        margin:auto;
        /* flex-wrap: wrap; */
        justify-content: center;
        /* align-items: center; */
    }

.articles-add{
    width: 17%;
    display: block
    /* border-radius: 20px; */
  }
  .articles-add-moblie{
  display: none;
}
    .articles-calls{
        width: 17%;
        margin-left: 4%;
       background: #DEE0F3;
       border-radius: 10px;
       padding: 2%;
       text-align: center;
    }
    .artilces-list-posts{
        width: 95%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        /* background-color: grey !important; */

    }
    .articles-list-post{
        width: 27%;
        margin-left: 2%;
        /* padding-top: 20px; */
        /* background-color: red; */
    }
    .articles-list-card{
        border: 1px solid #dfe1e5;
        box-shadow: none;
        cursor: pointer;
        opacity: 1;
        border-radius: 5px 5px 0px 0px;
         padding: 2%;
         padding-top: 8%;
         padding-bottom: 5%;
         height: 300px;
    }
    .articles-list-card p{
        width: 70;
         margin: auto;
        font-size: 110%;
        text-align: center;
        padding-top: 8%;
         padding-bottom: 2%;
    }
    .articles-user-doc{
    
        display: flex;
        float: left;
        /* background-color: red; */
        width: 20%;
    }
    .articles-user-doc img{
      width: 35%;
    }
    .articles-user-doc   i{
        font-size: 125%;
        margin-left: 10%;
    }
    
 }

   /* // moblie view */

@media screen and (max-width: 1023px) {
    .articles-creaate-sub{
      
        margin-top: 2%;
        width: 95%;
        /* margin:auto; */
        display: none;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

.articles-add{
  display: none;
    /* border-radius: 20px; */
  }
  .articles-add-moblie{
    width: 30%;
  }
    .articles-calls{
        width: 50%;
        /* margin-left: 4%; */
       background: #DEE0F3;
       border-radius: 10px;
       /* margin: auto; */
       padding: 2%;
       text-align: center;
    }

    .artilces-list-posts{
        /* display: flex; */
    }
    .articles-list-post{
        padding-top: 5%;
        width: 95%;
        margin-left: 2%;
    }
    .articles-list-card{
        border: 1px solid #dfe1e5;
        box-shadow: none;
        cursor: pointer;
        opacity: 1;
        border-radius: 5px 5px 0px 0px;
         padding: 4%;
         padding-top: 10%;
         padding-bottom: 10%;
    }
    .articles-list-card p{
        width: 90%;
        margin: auto;
        font-size: 100%;
        text-align: center;
    }
    .articles-user-doc{
    
        display: flex;
        float: left;
        /* background-color: red; */
        width: 20%;
        position: relative;
        bottom: 2px;
    }
    .articles-user-doc img{
      width: 40%;
    }
    .articles-user-doc   i{
        font-size: 156% !important;
        margin-left: 10%;
    }

}