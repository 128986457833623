

.newarticle-later{
    width:100%;
    box-shadow: 0 0 40px 0px rgba(0, 0, 0, 0.17);
    background:#fff;
    margin-top:1% ;
    margin-bottom: 3%;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 10%;
    padding-top: 10%;
    overflow-x: scroll;
    height: 300px;
}

.react-markdown--toolbar .btn {
    margin-bottom: 10px;
    border: 0;
    box-shadow: none;
    margin-left: 20px;
    cursor: pointer;
    margin-top: 10px;
    width: 20%;
    /* all: unset; */
      font-size: 0.8rem;
       padding: 0.875rem 3.9rem;
      border-radius: 1.5rem !important;
   transition: all 0.5s ease-in-out;
      font-weight: 700;
      display: inline-block;
      color: #fff !important;
}
.viewButton-0-2-6 {
    display: none !important;
}

.react-markdown--plain-markdown-input__left-border{
    position: relative;
    /* // right: 3%; */
}
.react-markdown--toolbar {
    /* // display: -ms-flexbox; */
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    /* // -ms-flex-wrap: wrap; */
    /* //  flex-wrap: wrap; */
    -ms-flex: 1  auto;
}

.btn-default{
    /* width: 20%;
    display: flex; */
/* //    width:50% !important; */
/* //    margin-left: 10% !important;
//    background-color: red; */
   background: #031E5D !important;
   color: #fff !important;
/* //    padding-left: 30% !important;
//    padding-right: 30% !important; */
}
.btn-group{
    
}
.btn-group:nth-child(3){
    display: none;
}
.btn-save {
    background: #ffe08a !important;
    color: #fff !important; 
}
.btn-publish  {
    background: #48c78e!important;
    color: #fff !important; 
}



.article-users-title{
    width: 90%;
    margin: auto;
    display: flex;
    margin-left: 4%;
    margin-right: 5%;
    /* // border-bottom: rgba(0, 0, 0, 0.17) solid 1px; */
}
.article-users{
    width: 90%;
    display: flex;
    margin-left: 4%;
    margin-right: 5%;
    border-top: rgba(0, 0, 0, 0.17) solid 1px;
    margin: auto;
}

.article-name{
    width: 40%;
    /* // background-color: red; */
    padding-top: 2%;
    padding-bottom: 2%;
    /* // border-bottom: rgba(0, 0, 0, 0.17) solid 1px; */
}
.article-email{
    width: 40%;
    /* // background-color: red; */
    padding-top: 2%;
    padding-bottom: 2%;
    /* // border-bottom: rgba(0, 0, 0, 0.17) solid 1px; */
}
.article-pick{
    width: 8%;
    margin-left: 1%;
    margin-right: 1%;
    /* // background: red;
    // padding: 0%;/@at-root */
}
.article-pick p{
    font-size: 70%;
}
.article-pick-sub {
    /* // margin-top:0.4%; */
    background: grey;
    width: 30px;
    height: 30px;
    /* // text-align:center; */
    float:right;
    /* // margin-bottom: 2%;
    // padding-bottom: 20px; */
    border-radius: 50%;
    text-align: center;
    position: relative;
    left: 15%;
    padding-top: 7%;
  }
  .article-pick-sub i{
      font-size: 120%;
      text-align: center;
      color: #fff;
  }

 .article-pick-list a{
     margin-left: 2%;
     margin-right: 2%;
 }
  .pick-color{
    /* // background: grey; */
    width: 30px;
    height: 30px;
    margin-left: 3%;
    /* // text-align:center;
    // float:right;
    // margin-bottom: 2%;
    // padding-bottom: 20px; */
    border-radius: 50%;
  }
  .article-default{
    background-color: #fff;
  }
  .article-blue{
  background-color: #0F1B41;
  }

  .article-green{
    background-color: #10BA9B;
 }
  
 .article-red{
    background-color: #F47960;
 }
  
 .article-yellow{
    background-color:#E6BF52
 }

  
  /* web  view */
 @media (min-width: 1024px)  {
    .new-article-info{
        display: flex;
        margin-top: 2%;
        margin-bottom: 2%;
    }
    .new-article-body{
        width: 90%;
        margin: auto;
    }
    .article-title{
        width:40%;
        /* // background: red; */
        /* // padding-right: 3%; */
    }
    .article-pick-list{
        width: 10%;
        height: 100px;
        position: absolute;
        margin-top: 3%;
        background: grey;
        padding: 0.5%;
        border-radius: 10px;
        display: flex;
        flex-wrap: wrap; 
          justify-content: center;
          padding-top: 1.4%;
          z-index: 10;
     } 
    .article-category{
        width: 30%;
        margin-left: 3%;
        /* // background-color: red; */
        padding-left: 3%;
    }
.newarticle-photo-click{
    float:right;
    position:relative;
    left:7px;
    font-size:90%
}

.article-save{
    width:15%;
    /* // background: #ffe08a; */
  }
  .new-article-photo{
    border-radius: 10px;
    border: thistle solid 1px;
    position: absolute;
    width: 20%;
    /* background: red; */
    height: 200px;
    z-index: 6;
    top: 24%;
}
.new-article-photo img{
    width: 90%;
}
.article-pick-moblie {
    /* // margin-top:0.4%; */
  
    display: none;
  }



 }
 /* // moblie view */

@media screen and (max-width: 1023px) {
    .new-article-info{
        display: flex;
        margin-top: 2%;
        margin-bottom: 3%;
    }
    
    .new-article-body{
        width: 99%;
        margin: auto;
    }
    .article-title{
        width:60%;
        /* background: red; */
    }
    .article-category{
       display: none;
    }
    .article-save{
    display: none;
        /* // background: #ffe08a; */
    }
    .article-pick-list{
        width: 30%;
        height: 100px;
        position: absolute;
        margin-top: 8%;
        background: grey;
        padding: 0.5%;
        border-radius: 10px;
        display: flex;
        flex-wrap: wrap; 
          justify-content: center;
          padding-top: 1.4%;
          z-index: 10;
     } 
    .article-pick-moblie {
        /* // margin-top:0.4%; */
        background: grey;
        width: 30px;
        height: 30px;
        /* // text-align:center; */
        /* float:right; */
        /* // margin-bottom: 2%;
        // padding-bottom: 20px; */
        color: #fff;
        border-radius: 50%;
        text-align: center;
        position: relative;
        margin-left: 1.5%;
        /* left: 15%; */
        padding-top: 1%;
        display: block;
    
      }
      .newarticle-photo-click{
        /* float:right; */
        /* background: red; */
        width: 3%;
        position:relative;
        display: block;
        /* left:3px; */
        top: 29px;
        font-size:100%;
        right:34px

    }
    .new-article-photo{
        border-radius: 10px;
        border: thistle solid 1px;
        position: absolute;
        width: 30%;
        /* background: red; */
        height: 120px;
        z-index: 6;
        /* top: 24%; */
    }
    .new-article-photo img{
        width: 90%;
    }
    .article-category-mob{
    
        /* background: red; */
        width: 55%;
        margin: auto;
    }
    .article-save-modal{
        display: flex;
        width: 100%;
        text-align: center;
        margin: auto;
        flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    }
}