.loginText {
    color: #007bff;
    font-weight: bold;
    cursor: pointer;
}

.successMessage {
    color: green;
    font-weight: bold;
}
.registart-input{
    width: 55%;
    /* padding-top: 1rem;; */
}