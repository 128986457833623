.users-lists{
    /* margin-top: 1%; */
}
.users-add{
    width: 17%;
    margin-left: 4%;
   background: #DEE0F3;
   border-radius: 10px;
   padding: 2%;
   text-align: center;
}
.users-add-icon{
 font-size: 250% !important;
 margin-top: 45%;
}
.users-search{
    
 /* padding-left: 35%; */
 /* background-color: red; */
 margin-top: 30%;
 width:100%;
 margin: auto;
 height: 50px;
 /* margin-bottom: 10%; */
 
}
.users-search-sub{
    width: 30%;
    /* background-color: red !important; */
    float: right;
    position: relative;
    right: 7%;
}
.users-input-search{
    width: 80%;
    /* background-color: red; */
}
.users-color{
    background: #031E5D !important;
    color: #fff;
    margin-left: 10%;
    width: 120%;
}
.users-list-cols{
    width: 90%;
    margin: auto;
    margin-top: 3%;
}
.users-list-sub{
   display: flex; 
   border-bottom: 1px #C4C4C4 solid;
   border-top: 1px #C4C4C4 solid;
   padding-top: 1%;
   padding-bottom: 1%;
}
.users-list-cols-sub{
    display: flex; 
   border-bottom: 1px #C4C4C4 solid;
   /* border-top: 1px #C4C4C4 solid; */
   padding-top: 1%;
   padding-bottom: 1%;
}
.users-Name{
    width: 20%;
    /* background-color: red; */
    text-transform: capitalize;
    font-size: 95%;
    text-align: left;
}
.users-Age{
    width: 5%;
    /* background-color: blue; */
    text-transform: capitalize;
    font-size: 95%;
    text-align: left;
}
.users-Gender{
    width: 7%;
    /* background-color: blue; */
    text-transform: capitalize;
    font-size: 95%;
    text-align: left;
}
.users-School{
    width: 20%;
    /* background-color: burlywood; */
    text-transform: capitalize;
    font-size: 95%;
    text-align: left;
}
.users-Phone{
    width: 15%;
    text-transform: capitalize;
    font-size: 95%;
    text-align: left;
}
.users-Class{
    width: 8%;
    /* background-color:blanchedalmond; */
    text-transform: capitalize;
    font-size: 95%;
    text-align: left;
}
.users-e-Mail{
    width: 20%;
    /* background-color:blanchedalmond; */
    text-transform: capitalize;
    font-size: 95%;
    text-align: left;
}
.users-register{
    width: 90%;
    margin: auto;
}
.users-register h2{
    text-align: center;
    margin-bottom: 4%;
}
.user-list-div{
    width:100%;
    height: auto;
    background-color: #fff;
    position: absolute;
 
}