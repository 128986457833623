.categoris{
    margin-top: 2%
}
.categoris h2{
    font-size: 150%;
    color: #0F1B41;
    margin-left: 5%;
}
.catgoris-list-cols{
    width: 90%;
    margin: auto;
    margin-top: 1%;
}

.categoris a{
    float: right;
    position: relative;
    right: 5%
}
.categories-list-sub{
 
        display: flex; 
        border-bottom: 1px #C4C4C4 solid;
        border-top: 1px #C4C4C4 solid;
        padding-top: 1%;
        padding-bottom: 1%;
}
.categories-Name{
    width:60%
}
.categories-status{
    width:30%
}