
.result-heard{
 
padding-top:2%;
}
.result-heard h3{
    font-size: 170%;
    text-align: center;
    color: #fff;
}
.result-heard h4{
    font-size: 140%;
    text-align: center;
    color: #000;
    
}
.result-heard h5{
    font-size: 140%;
    text-align: center;
    color: #000;
    margin-bottom: 5px;
}



.tab-header-title{
font-size: 150%;
text-align: center;
padding-top: 3%;
font-weight: bold;
color: #24232a;
font-weight: 500;
line-height: 1.2;
}
.result-heard h6{
    text-align: center;
    font-size: 120%;
    margin-top: 1%;
    /* font-weight: bold; */
 }

.result-section-tab{
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
}
.result-carosel-body{
    width: 100%;
    background: white;
    padding-top: 13%;
    padding-bottom: 13%;
    /* height: 440px; */
   
 }
 .result-carosel-body h1{
     font-size: 200%;
     text-align: center;
 }
 .result-carosel-body p{
    text-align: center;
    font-size: 110%;
    margin-top: 3%;
 }
 .result-carosel-body h2{
    text-align: center;
    font-size: 150%;
    margin-top: 3%;
    color: #48c774;
 }
 
 .result-carosel-body h3{
    text-align: center;
    font-size: 120%;
    margin-top: 1%;
    font-weight: bold;
    /* color: #48c774; */
 }
 .result-heard h4{
      color: #fff;
 }

 .result-heard h5{
    color: #fff;
}
.result-heard h6{
    color: #fff;
}


 
   /* //web view */
   @media (min-width: 1024px)  {
   
    .result-full-body{
        background: red;
        background-color: #08181e;
        /* height: 40vh; */
        background-image: url(https://quizac.com/img/images.png);
        background-position: 50%;
        background-repeat: repeat;
        margin: 0;
        padding:0;
        border: 0;
        /* margin-top:4%; */
        padding-top: 4%;
    }
    .result-heard div{
        width:22%;
        margin: auto;
        margin-top: 2%;
      }
    .result-heard ul{
        width:20%;
        margin: auto;
        margin-bottom: 1.5%;
        margin-top: 1.5%;
        /* background: red; */
        padding-left: 1%;
      }
      .result-heard ul li{
       display: inline;
       margin-right: 3%;
      }
      .result-sub-nav{
        width: 65%;   
        margin: auto; 
        
       }
       .result-tab-section{
        margin: auto;
        width: 89%;
        box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
        margin-top: 20px;
        margin-bottom: 30px;
    }
   }


  /* // moblie view */
  @media screen and (max-width: 1023px) {
    .result-full-body{
        background: red;
        background-color: #08181e;
        height: 27vh;
        background-image: url(https://quizac.com/img/images.png);
        background-position: 50%;
        background-repeat: repeat;
        margin: 0;
        padding:0;
        border: 0;
        margin-top:13%;
        padding-top: 25%;
        
    }
    .result-heard div{
        width:30%;
        margin: auto;
        margin-top: 2%;
      }
    .result-heard ul{
        width:60%;
        margin: auto;
        margin-bottom: 1.5%;
        margin-top: 1.5%;
        /* background: red; */
        padding-left: 1%;
      }
      .result-heard ul li{
       display: inline;
       margin-right: 3%;
      }
      .result-sub-nav{
        width: 100%;   
        margin: auto; 
        
       }
       .result-tab-section{
        margin: auto;
        width: 97%;
        box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
        margin-top: 20px;
        margin-bottom: 30px;
    }
  }