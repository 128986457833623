

.modal-page{
    padding-top: 20%;
    padding-bottom:20%;
    /* // background:red */
}
.modal-sub { 
    position: fixed;
    z-index: 100;
    padding-top: 20px; /*  Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.9);
  }
  .modal-final {
    margin-top:0.4%;
    background: grey;
    width: 30px;
    height: 30px;
    text-align:center;
    float:right;
    margin-bottom: 2%;
    padding-bottom: 20px;
    border-radius: 50%;
  }
  .closeModal {
    color: #ffffff;
    font-size: 200%;
    font-weight: bold;
    margin: auto;
    position: relative;
    bottom: 15px;
  }
  .closeModal :hover,
  .closeModal :focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
  .box1 {
    /* background: red; */
    /* display: flex; */
    /* flex-direction: column; */
    width: 53%;
    position: relative;
    bottom: 2%;
    height: 100%;
    margin: auto;
  }
  .robotics {
    width: 45%;
    height: auto;
    margin: 0% 25%;
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.18);
  }
  .bluedesign {
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
    background-image: url("https://res.cloudinary.com/codepally/image/upload/v1623249557/ridora%20website/Vector_17_egz9bu.svg");
  }
  .bluedesign1 {
    margin: auto;
  }
  .bluedesign img {
    margin-top: 20%;
    margin-left: 20%;
    width: 60%;
  }
  .text {
    margin: 10%;
    color: #000000;
    text-align: left;
    font-weight: 500;
    font-size: 14px;
  }
  .courseTitle {
    color: #3cc3e0;
    margin-left: 10%;
    width: 130.04px;
    height: 20.01px;
    font-weight: bold;
    font-size: 14px;
    line-height: 65px;
  }
  .text2 {
    /* margin-left: 30px;
    margin-top: 25px; */
    margin-left: 10%;
    color: #000000;
    text-align: left;
    font-weight: 500;
    font-size: 11px;
  }
 .modal-click-add button{
   background:#FFA82B;
   color: #ffffff;
   margin-bottom: 4%;
 }
 .modal-click-add p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 180%;
    line-height: 65px;
    text-align: right;
    color: #131313;
    margin-right: 3%;
 }
.robotics-white{
    background-color:#fff;
    width: 80%;
    height: auto;
    margin: auto;
    border-radius: 10px;
    background: #ffffff;
    position: relative;
    top:2%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.18);
    padding-bottom: 10%;
    padding-top: 10%;
}
.robotics-white h4{
    /* margin-left: 7%; */
  /* margin-top: 20% !important; */
  font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 200%;
    position: relative;
    text-align: center;
    top:20px;
    /* // line-height: 64px; */
    /* width:60%; */
    color: #00215B;

}

.modal-input{
    width:93%;
    margin:auto;
    margin-top:3%;
    padding-bottom:3%
}
.modal-white-add a{
    width:50%;
    margin-right:2% ;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 6px;
    text-align: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 120%;
    line-height: 33px;
    color: #3CC3E0 !important;
}

.modal-white-add{
    display: flex;
    width:70%;
    margin:auto;
}
.modal-write{
  width: 90%;
  margin: auto;
}
.modal-write p{
  font-family:'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 110%;
  line-height: 200%;
  margin-top: 4%;
  margin-bottom: 4%;
  /* or 48px */
  
  
  color: #000000;
}
.modal-write h5{
  font-family:'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 140%;
  line-height: 200%;
  margin-top: 4%;
  margin-bottom: 4%;
  /* or 48px */
  text-align: right;
  color: #db3236;
}


  /* web  view */
  @media (min-width: 1024px)  {
    .box1 {
      /* background: red; */
      /* display: flex; */
      /* flex-direction: column; */
      width: 53%;
      position: relative;
      bottom: 2%;
      height: 100%;
      margin: auto;
    }
    .robotics-white{
      background-color:#fff;
      width: 80%;
      height: auto;
      margin: auto;
      border-radius: 10px;
      background: #ffffff;
      position: relative;
      top:2%;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.18);
      padding-bottom: 10%;
      padding-top: 10%;
  }


  }


/* // moblie view */

@media screen and (max-width: 1023px) {
  .box1 {
    /* background: red; */
    /* display: flex; */
    /* flex-direction: column; */
    width: 90%;
    position: relative;
    bottom: 2%;
    height: 100%;
    margin: auto;
  }
  .robotics-white{
    background-color:#fff;
    width: 100%;
    height: auto;
    margin: auto;
    border-radius: 10px;
    background: #ffffff;
    position: relative;
    top:2%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.18);
    padding-bottom: 10%;
    padding-top: 10%;
}


}