.course-quiz-head {
    width: 40%;
    margin: auto;
    /* background-color: red; */
    /* display: flex; */
}

.course-quiz-head h5{
    font-size: 1rem;
    font-weight: 700;
    /* line-height: 40px; */
    text-shadow: none;
    overflow-wrap: break-word;
    word-break: break-word;
    text-align: center;
}
.course-quiz-head h6{
    font-size: 1rem;
    font-weight: 700;
    /* line-height: 40px; */
    text-shadow: none;
    overflow-wrap: break-word;
    word-break: break-word;
    text-align: center;
}
.quiz-button-to-start{
    display: flex;

    justify-content: center;
    text-align: center;
     margin: auto;
    justify-content: center;
    margin-top: 4%;
}
.quiz-button-to-back{
    width: 30%;
   background-color: #D80739;;
   color: #fff;
   margin-right: 4%;
}
.quiz-button-to-back i {
    margin-right: 3%;
}

.quiz-button-to-back:hover{
    background-color: #D80739;;
    color: #fff;

    width: 30%;
    color: #fff;
    margin-right: 4%;
}
.quiz-button-to-next{
    width: 30%;
    background: rgb(38, 137, 12);
    color: #fff;
}
.quiz-button-to-next:hover{
    width: 30%;
    background: rgb(38, 137, 12);
    color: #fff;
}
.main-dashboard{
    margin-top: 5%;
}
.quiz-body{
    width:100% !important;
    background-color: #431b93;
    /* height: auto */
    height: 100vh;
    padding-top: 6%;
}






.questions-time{
    width:25%;
    /* background: black; */
}
.questions-time-sub{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: auto;
    background: #10BA9B;
    padding-top: 10%;
}
.questions-time-sub h4{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 140%;
    /* or 67px */
    text-align: center;
    color: #FFFFFF;
}




.questions-number h4{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 140%;
    /* identical to box height, or 42px */
    
    text-align: center;
    
    color: #FAFAFA;
}
.questions-number h6{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 140%;
    /* identical to box height, or 34px */
    
    text-align: center;
    
    color: #FAFAFA;
    
}
.questions-img-list{
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    /* background-color: black; */
    width: 30%;
    margin: auto;
    margin-top: 3%;
    margin-bottom: 3%
}
.questions-img-list p{
    text-align: center !important;
}
.questions-img-list p img{
    flex-shrink: 0;
    min-width: 80%;
    min-height: 0%
}

.questions-title1 {
    animation: 2s anim-popoutin ease ;
    animation-duration: 4s;
    text-align: center;
    /* margin-top:4%; */
    margin-bottom:4%;
    color: #FFF;
  }
  
  
.box-question{
    width: 10% !important;
    background-color: red;
    margin-left: 2%;
}
.question-image-holder{
    width: 55%;
}
.question-image{
    /*height: 500px;*/
    max-width: 100%;
    width: 100%;
    border-radius: 15px;
    margin-bottom: 10px;
    object-fit: contain;
}

.question section{
    width: 40%;
    margin-left: 2%;
}

.question-text{
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 1.7;
    font-family: GOT;
    color: rgba(0,0,0,0.6);
    float: left;
}

.question-text-holder button{
    color: white;
    background: rgb(48, 48, 48);
    padding: 12px 25px;
    border: none;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 8px;
    float: right;
    cursor: pointer;
    margin-bottom: 10px;
}
.uestion-time{
    height: 0px;
  float: left;

}
.question-option1{
    border-radius: 8px;
    box-shadow: 0 5px 13px 1px rgba(0,0,0,0.1);
    padding: 5px;
    /* width: 100%; */
    font-size: 17px;
    font-weight: 600;
    color: rgba(0,0,0,0.8);
    border: 0;
    background: white;
    margin: 13px 0;
    display: flex;
    align-items: center;
    font-family: GOT;
    letter-spacing: 1px;
    cursor: pointer;
    animation: 2s  expale-cirle  ease ;
}
.question-option1 span{
    height: 60px;
    width: 50px;
    font-size: 18px;
    text-align: center;
    /* background: rgb(235, 235, 237); */
    /* color: rgba(0,0,0,0.6); */
    line-height: 3.2;
    border-radius: 5px;
    font-weight: bold;
    margin-right: 20px;
}
.exam-color{
    background-color: #431b93;
    color: black;
}
.exam-color1{
    background-color: #26890C;
    color: black;

}
.exam-color2{
    background-color: #E21B3C;
    color: black;
}
.exam-color3{
    background-color: #d09f36;
    color: black;
}
.exam-color4{
    background-color: #49B6FF    ;
    color: black;
}
.exam-color5{
    background-color:  #BD9090  ;
    color: black;
}
.exam-color6{
    background-color:  #C9E7E5;
    color: black;
}


.question-option:hover{
    box-shadow: 1px 6px 17px 8px rgba(0,0,0,0.1);
}

.question-option:hover span{
    background: rgb(48, 48, 48);
    color: whitesmoke;
}

.correct{
    background: #2daf1c;
    color: whitesmoke;
}

.wrong{
    background: #fe3636;
    color: whitesmoke;
}

@media (max-width: 400px) {
    .question {
        display: block;
    }
    .question-image-holder{
        width: 100%;
    }
    .question section{
        width: unset;
    }
    .questions-progress{
        position: unset !important;
        margin-bottom: 10px;
    }
}

@keyframes  expale-cirle {
    0% {
        opacity: 0;
        bottom: 10px;
      }
      75% {
        opacity: 0.5;
        top: 0px;
      }
      100% {
        opacity: 1;
      }
  }
  
@keyframes anim-popoutin {
    0% {
      color: black;
      transform: scale(0);
      opacity: 0;
      text-shadow: 0 0 0 rgba(0, 0, 0, 0);
    }
    25% {
      color: red;
      transform: scale(2);
      opacity: 1;
      text-shadow: 3px 10px 5px rgba(0, 0, 0, 0.5);
    }
    50% {
      color: black;
      transform: scale(1);
      opacity: 1;
      text-shadow: 1px 0 0 rgba(0, 0, 0, 0);
    }
    100% {
      /* animate nothing to add pause at the end of animation */
      transform: scale(1);
      opacity: 1;
      text-shadow: 1px 0 0 rgba(0, 0, 0, 0);
      text-align: center;
    }
  }


 
/* web */
@media (min-width: 1024px)  {

    .quiz-head{
        width:90%;
        margin-bottom: 1.5rem;
        padding: 5px;
        background-color: rgb(255, 255, 255);
        box-shadow: rgb(0 0 0 / 15%) 0px -3px inset;
        border-radius: 2px;
        text-align: center;
        margin: auto;
        padding-top: 1%;
        padding-bottom: 1%;
    }
    .quiz-head h1{ 
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 40px;
        text-shadow: none;
        overflow-wrap: break-word;
        word-break: break-word;
    }
    .quiz-ridora-logo img{

        text-align: center;
        justify-content: center;
        text-align: center;
         margin: auto;
        justify-content: center;
        margin-top: 5%;
        margin-bottom: 4%;
    }
    .course-quiz-head img{
        width: 45%;
        text-align: center;
        justify-content: center;
        text-align: center;
         margin: auto;
        justify-content: center;
    }
    .quiz-taking{
        width: 36%;
        margin: auto;
        background: #FFFFFF;
        border-radius: 15px;
        height:auto;
        margin-top: 2%;
        padding-top: 4vh;
        padding-bottom: 4vh;
   }
   .questions{
    width:100% !important;
    background:#DFB092;
    /* height: auto */
    height: 100vh;
  }
   .question-time{
    height: 1px;
    padding-left: 10%;
    position: relative;
    top: 5%;
  }
  .questions-img1{
    width: 100%;
    /* background-color: red; */
    margin-top: 4.5%;
    display: flex;
    justify-content: center;
 }
  .questions-time-number1{
    /* padding-top: 0.5%; */
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.questions-title1{
    width:45%;
   
    /* background: blue; */
}
.questions-title1 h2{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 210%;
    line-height: 120%;
    /* or 67px */
    text-align: center;
    color: #FFFFFF;
    position: relative;
    top:40px
}
.questions-number{
    width:25%;
    /* background: yellow */
}
.questions-choice-boy1{
    padding-top: 0.5%;
    width: 96%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-bottom: 3%;
}
.questions-choice{
    /* background-color: blue; */
    width: 40%;
    padding-right: 1%;
    margin-left:4%
}
}


/* // moblie view */

@media screen and (max-width: 1023px) {

    .quiz-head{
        width:96%;
        margin-bottom: 1.5rem;
        padding: 5px;
        background-color: rgb(255, 255, 255);
        box-shadow: rgb(0 0 0 / 15%) 0px -3px inset;
        border-radius: 2px;
        text-align: center;
        margin: auto;
        padding-top: 1%;
        padding-bottom: 1%;
    }
    .quiz-head h1{ 
        font-size: 1rem;
        /* font-weight: 700; */
        line-height: 40px;
        text-shadow: none;
        overflow-wrap: break-word;
        word-break: break-word;
    }
    .quiz-ridora-logo img{
        width: 45%;
        text-align: center;
        justify-content: center;
        text-align: center;
         margin: auto;
        justify-content: center;
        margin-top: 5%;
        margin-bottom: 4%;
    }
    .course-quiz-head img{
        width: 45%;
        text-align: center;
        justify-content: center;
        text-align: center;
         margin: auto;
        justify-content: center;
    }
    .quiz-taking{
        width: 96%;
        margin: auto;
        background: #FFFFFF;
        border-radius: 15px;
        height:auto;
        margin-top: 2%;
        padding-top: 4vh;
        padding-bottom: 4vh;
   }
   .questions{
    width:100% !important;
    background:#DFB092;
    /* height: auto */
    height: auto;
    padding-top: 10%;
   }

   .question-time{
       display: none;
    /* height: 1px;
    padding-left: 10%;
    position: relative;
    top: 5%; */
    }   
    .questions-img1{
        width: 100%;
        /* background-color: red; */
        margin-top: 4.5%;
        display: flex;
        text-align: center;
       justify-content: center;
    }
    .questions-img1 p {
        text-align: center !important;
    }
    .questions-img1 p img{
        width: 60%;
        margin-left: 10%;
    }
    .questions-time-number1{
        /* padding-top: 0.5%; */
        /* display: flex; */
        align-items: center;
        justify-content: center;
        position: relative;
    }
    .questions-title{
        width:95%;
       
        background: blue;
    }
    .questions-title1 h2{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: normal;
        font-size: 120%;
        line-height: 120%;
        /* or 67px */
        text-align: center;
        color: #FFFFFF;
        position: relative;
        margin-top: 9%;
        /* top:40px */
    }
    .questions-number{
        display: none;
        /* background: yellow */
    }
    .questions-choice-boy1{
        padding-top: 0.5%;
        width: 96%;
        /* display: flex; */
        /* flex-wrap: wrap;
        justify-content: center;
        align-items: center; */
        padding-bottom: 3%;
    }
    .questions-choice{
        /* background-color: blue; */
        width: 95%;
        padding-right: 1%;
        margin-left:4%
    }
}


