

.setting-title{
    border-bottom: solid 1px #E5E5E5;
}
.setting-title h2{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: bold;
    font-size: 150%;
    line-height: 120%;
    display: flex;
    align-items: flex-end;
    letter-spacing: 0.005em;
    color: #11142D;
}




  /* web  view */
  @media (min-width: 1024px)  {

    .setting-body{
        width: 60%;
        margin: auto;
        /* background-color: red; */
        margin-top: 3%;
    }
    

}

/* // moblie view */

@media screen and (max-width: 1023px) {

    .setting-body{
        width: 95%;
        margin: auto;
        /* background-color: red; */
        margin-top: 3%;
    }
    
}

