.onboarding-from{

}
.onboarding-input{
    width: 70%;
    /* padding-top: 1rem;; */
}
.onboarding-from {
    position: relative;
    /* top: 5%; */
}
.onboarding-from h3{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    /* line-height: 64%; */
    color: #A4ADC8;
    text-align: center;
    text-transform: uppercase;
    /* margin-top: 1.5%; */
}
.onboarding-from p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size:110%;
   
    /* line-height: 40%; */
    color: #A4ADC8;
    text-align: center;
    text-transform: uppercase;
    /* margin-top: 1.5%; */
}
.onboarding-p p{
    font-family: 'Poppins';
    font-style: normal;
    /* font-weight: bold; */
    font-size:90%;
    margin-top: 3%;
    /* line-height: 40%; */
    color: #A4ADC8;
    text-align: left ;
    text-transform: uppercase;
}

.onboarding-info-width {
    width: 65%;
    margin: auto;
    padding: 4%;
    padding-top: 1%;
}
.onboarding-bottom p{
    text-align: center;
}