.login {
}

.form-group {
  position: relative;
 

}
.input-custom {
  all: unset;
  border-bottom: 2px solid #d4d4d4;
  display: block;
  width: 100%;
}
.form-control:focus {
  all: unset;
  border-bottom: 2px solid #337AB7;
  /* @extend .input-custom; */
  /* border-bottom: 2px solid $primary; */
  display: block;
  width: 100%;

  /* // &::placeholder {
  //   visibility: none;
  //   color: red;
  // } */
}
