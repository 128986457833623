
.sidenav {
    height: 100%;
    width: 260px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    /* background-color:hsl(0, 0%, 96%); */
    background: #B9BABB;
    overflow-x: hidden;
    padding-top: 20px;
    color: #fff !important;
    padding-top: 7%;
    
}

.sidenav-name-sub p{
 font-size: 260%;
 text-align: center;
 text-transform: uppercase;
 padding-top: 10%;
}
.sidenav-name-sub{
    width:80px;
    height :80px;
    background: #3CC3E0;
    border-radius: 50%;
    margin: auto;
}
.sidenav h3{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 140%;
    /* line-height: 42px; */
    /* identical to box height */
    color: #fff;
    text-align: center;
}
.sidenav h5{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 101%;
    line-height: 21px;
    text-align: center;
    color: #fff;
    margin-bottom: 19%;
}

.dashboard-list ul{
  margin-top: 20%;
}
.dashboard-list-sub{
    /* background: red; */
    width: 80%;
    margin: auto;
    text-align: center;
    margin-top: 15%;
    margin-bottom: 15%;
    
}


.dashboard-list-sub i{
 text-align: center !important;
 font-size: 150%;
}
.dashboard-list-sub p{
    text-align: center;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: normal;
        font-size: 120%;
        /* line-height: 42px; */
        /* identical to box height */
        color: #fff;
        text-align: center;
    }
    .dashboard-main{
        width: 78%;
        height: auto;
        padding: 4%;
        /* margin-top: 3%; */
        padding-left: 7%;
        /* background-color: red; */
        margin-left: 21%; 
        padding-top:7%; 
        /* margin: auto; */
    }
   .dashboard-sub{

   }
.dashboard-title{
    display: flex;
    border-bottom: solid 1px #E5E5E5;
    /* margin-bottom: 5%; */
    /* margin-top: 5%; */
}
.dashboard-title-title{
    width: 35%;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    
    color: #000000;
}


.dashboard-title-modules{
    width: 20%;
    font-family: 'Roboto';
font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 28px;

color: #000000;

}
.dashboard-title-status{
    width: 20%;
    font-family: 'Roboto';
    text-align: center;
font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 28px;

color: #000000;

}

.dashboard-title-remark{
    width: 15%;
    font-family: 'Roboto';
font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 28px;

color: #000000;

}
.dashboard-course{
    display: flex;
    margin-top: 2%;
    margin-bottom: 5%;
    /* background-color:red; */

    /* border-bottom: solid 1px #E5E5E5; */
}
.dashboard-title-title img{
    float: left;
    width: 22%;
    border-radius: 50%;
}
.dashboard-title-title h5{
    font-size: 90%;
    position: relative;
    left: 2%;
}
.dashboard-title-title h6{
    font-size: 80%;
    position: relative;
    left: 2%;
    bottom: 5px;
}

.dashboard-title-modules-sub{
    width: 50%;
    padding: 6px;
    background: #B9BABB;
    border-radius: 100px;
    text-align: center;
    color: #fff;
}
.dashboard-title-status-sub{
    width: 80%;
    padding: 6px;
    background: #FFA82B;
    border-radius: 100px;
    text-align: center;
    font-size: 80%;
    margin: auto;
    color: #fff;
}
.dashboard-title-remark p{
    font-size: 80%;
    text-align: center;
}