.mide-link-custom{
    /* background: black; */
    width: 50%;
   margin: auto;
   text-align: center;
   margin-left: 15%;
}
.mide-link-custom ul li{
    /* background-color: red; */
    display: inline-block;
    margin-left: 10%;
    padding-top: 2%;
    position: relative;
    /* right: 10%; */
}
.dp-icon-body{
    margin-right: 20px;
    /* background:black */
}
.dp-icon-body b{
    font-size: 80% !important;
    margin-right: 4%;
    position: relative;
    bottom: 10%;
}
.header-drop{
    background-color:#fff;
    width: 100%;
    /* height: 100px; */
    border-radius: 5px;
    position:absolute;
    margin-top: 105%;
    right: 50px;
    float: left;
    padding-top: 10%;
}
.header-drop ul li{
    border-top: 1px solid #dfe1e5;;
    /* border-bottom: 1px solid #dfe1e5;;/ */
    padding-left: 10%;
    font-size: 90%;
    padding-top: 5%;
    padding-bottom: 5%;
}
.dp-icon{
    
    width:40px;
    height: 40px;
    border-radius: 50%;
    background-color: grey;
    text-align: center;
    padding-top: 7%;
}

.dp-icon i{
  font-size: 180%;
  text-align: center;
  color:#fff
}





/* web */
@media (min-width: 1024px)  {
   
    .web-hide{
        width: 70%;
        /* display: inline !important */
    }
}

/* // moblie view */

@media screen and (max-width: 1023px) {
    .web-hide{
        display: none
    }
    .image-view img{
       position: relative;
       right: 20%
    }

}