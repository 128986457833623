.course-details{
margin-top: 5%;
}

.node {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display:inline-block;
    transition: all 1000ms ease;
    float:left;
    z-index: 10 !important;
    text-align: center;
    padding-top: 1%;
    justify-content: center;
}
.node img{
   width: 80%; 
   justify-content: center;
   text-align: center;
   position: relative;
   left: 10%;
   bottom: 3px;
}
.node i{
  text-align: center;
  font-size:130%;
  position: relative;
  color: #fff;

}
.activated {
    box-shadow: 0px 0px 3px 2px rgba(194, 255, 194, 0.8);
}

.divider {
    height: 100px;
    width: 2px;
    margin-left: 4px;
    transition: all 800ms ease;
    position: relative;
    left: 1.8%;
    z-index: 2;
    margin-top: 3%;
}

li p {
    /* margin-top: 10%; */
    display:inline-block;
    position: relative;
    top:15px;
    font-size: 90%;
    /* margin-left: 2%; */
    /* position: relative;
    top:10% */
    /* bottom: 5%; */
}
li h5{
    margin-bottom: 2%;
    position: relative;
    top:12px;
    font-size: 120%;
     font-weight: bolder;
}
li {
    list-style: none;
    line-height: 1px;
}


.blue { background-color: rgba(82, 165, 255, 1); }
.green{ background-color: rgba(92, 184, 92, 1) }
.red { background-color: rgba(255, 148, 148, 1); }
.grey { background-color: rgba(201, 201, 201, 1); }

.course-butom{
    float: right;
    width: 20%;
    /* width: 143px; */
    height: 44px;
    /* background: #0BB56E; */
    border-radius: 50px;
    text-align: center;
    padding-top: 2.5%;
    color: #fff;
    font-weight: bolder;
}