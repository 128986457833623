


.login-body{
 display: flex;
  padding: 2%;
}
.login-info{
    background: #031E5D;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.05), 0px 6px 30px rgba(0, 0, 0, 0.04), 0px 8px 10px rgba(0, 0, 0, 0.06);
    border-radius: 30px;
    width: 35%;
    padding: 2%;
    padding-top: 1rem;;
    padding-bottom: 15.30rem;;
    margin-right:  2.35rem;;;

}
.login-info p img{
    /* float: left; */
    width: 12%;
 text-align: left !important;
   color: #fff;
   margin-left: 5%;
   margin-top: 2rem;;
   margin-bottom: 6rem;;
   
}
.login-info h1{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 120%;
    color: #E7EFFD;
}
.login-input{
    width: 55%;
    padding-top: 2rem;;
}
.login-from {
    position: relative;
    top: 5%;
}
.login-from h3{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 120%;
    color: #A4ADC8;
    text-align: center;
    text-transform: uppercase;
    margin-top: 5%;
}
.main-body{
    width:100%;
    margin-top:4%
}
.input-info-holder {
    background: #FFFFFF;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 10%);
    border-radius: 3px;
}
.input-info-width{
    width: 65%;
    margin: auto;
    padding: 4%;
}
.input-info-width  p{
    text-align: center;
}
  /* web  view */
@media (min-width: 1024px)  {

    .login-from{
        width: 40%;
        /* margin-top:10% ; */
        padding-top: 3%;
        padding-bottom: 5%;
        margin: auto;
       }

}


/* // moblie view */

@media screen and (max-width: 1023px) {
    .login-from{
        width: 90%;
        /* margin-top:10% ; */
        padding-top: 3%;
        padding-bottom: 10%;
        margin: auto;
        margin-top: 20%;
       }

}
