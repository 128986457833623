
.code-body{
    width:100% !important;
    background:#C64243;
    padding-top: 10%;
    /* height: auto */
    height: 100vh;
}

.code-sub h2{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 192.19%;
    color: #FFFFFF;
    text-align: center;
}

.code-buttom{
    display: flex;
    margin-top: 4%;
    align-items: center;
    justify-content: center;
    position: relative;
}
.code-buttom button{
    width: 40%;
    margin-left: 2%;

border-radius: 10px;
}
.code-buttom-default{
    background: #031E5D;
    color: #fff;
}
.code-buttom-default:hover{
    background: #031E5D;
    color: #fff;
}
.code-buttom-close{
    background: #B9BABB;
    color: #fff;
}
.code-buttom-close:hover{
    background: #B9BABB;
    color: #fff;
}



/* web */
@media (min-width: 1024px)  {
    .code-sub{
        width: 40%;
        margin: auto;
        border: 3px dashed #FFFFFF;
        box-sizing: border-box;
        border-radius: 10px;
        height: 50vh;
    }
    .code-sub h5{
        width: 65%;
        margin: auto;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 140%;
        text-align: center;
        letter-spacing: -0.5px;
        
        color: #FFFFFF;
    }


}


/* // moblie view */

@media screen and (max-width: 1023px) {
    .code-sub{
        width: 95%;
        margin: auto;
        border: 3px dashed #FFFFFF;
        box-sizing: border-box;
        border-radius: 10px;
        height: 60vh;
        margin-top: 30%;
        padding-bottom: 20%;
        /* padding-top: 40px; */
    }
    .code-sub h5{
        width: 90%;
        margin: auto;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 140%;
        text-align: center;
        letter-spacing: -0.5px;
        
        color: #FFFFFF;
    }
    .Toastify{
        margin-top: 40px !important;
    }
    .error-message{
        margin-top: 50px !important;
    }
}